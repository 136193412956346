import { createGroupsAppController } from '@wix/social-groups-app/dist/src/controllers/appController';
import { createGroupsWidgetController } from '@wix/social-groups-app/dist/src/controllers/widget/GroupsWidgetController';
import { createAppController } from '@wix/social-group/dist/src/controllers/createAppController';
import {
  IAppData,
  IPlatformAPI,
  IPlatformServices,
  IWidgetControllerConfig,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import {
  GROUP_WIDGET_ID,
  GROUPS_LIST_DISCOVERY_WIDGET_ID,
  GROUPS_LIST_WIDGET_ID,
  GROUPS_WIDGET_ID,
} from '@wix/social-groups-common/dist/src/config/constants';
import { ControllerErrorLogger } from '@wix/social-groups-common/dist/src/loggers/ControllerErrorLogger';
import axios from 'axios';
import { Environment } from '@wix/social-groups-common/dist/src/loggers/sentryConfig';
import { getEnvironment } from '@wix/social-groups-common/dist/src/utils';
import { FedopsLogger } from '@wix/social-groups-common/dist/src/loggers/FedopsLogger';
import { ExperimentsProvider } from './experiments/ExperimentsProvider';

export const GROUPS_WIDGET_OLD = 'a1f0f11f-2c2d-40c9-b42e-1ce811d15465';
export const GROUP_WIDGET_OLD = '49acda86-4503-46bf-8418-89616e71d45b';
export const GROUPS_LIST_WIDGET_TEST = '65fdd948-296f-42a5-86fa-7ca750b664f8';

const controllerByType = {
  [GROUPS_WIDGET_ID]: createGroupsAppController,
  [GROUP_WIDGET_ID]: createAppController,
  [GROUPS_LIST_WIDGET_ID]: createGroupsWidgetController,
  [GROUPS_LIST_DISCOVERY_WIDGET_ID]: createGroupsWidgetController,
  [GROUPS_WIDGET_OLD]: createGroupsAppController,
  [GROUP_WIDGET_OLD]: createAppController,
  [GROUPS_LIST_WIDGET_TEST]: createGroupsWidgetController,
};
const testWidgets = [
  GROUPS_WIDGET_OLD,
  GROUP_WIDGET_OLD,
  GROUPS_LIST_WIDGET_TEST,
];

function getMetasiteId(
  platformServicesApis: IPlatformServices,
  platformApis: IPlatformAPI,
) {
  const bi = platformServicesApis.bi || platformApis.bi;
  return bi.metaSiteId;
}

function getMonitoring(
  platformServicesApis: IPlatformServices,
  platformApis: IPlatformAPI,
) {
  return platformServicesApis.monitoring || platformApis.monitoring;
}

export const initAppForPage = async (
  initParams: IAppData,
  platformApis: IPlatformAPI,
  wixCodeApi: IWixAPI,
  platformServicesApis: IPlatformServices,
) => {
  axios.defaults.adapter = axios.defaults.adapter || getDefaultAdapter();
  const monitor = ControllerErrorLogger.createMonitor(
    getMonitoring(platformServicesApis, platformApis),
    Environment.VIEWER_WORKER,
  );
  const metaSiteId = getMetasiteId(platformServicesApis, platformApis);
  const { isSSR } = getEnvironment(wixCodeApi);
  await ExperimentsProvider.setExperiments(platformApis, metaSiteId, isSSR);
};

export const createControllers = (
  controllerConfigs: IWidgetControllerConfig[],
) => {
  return controllerConfigs.map((config) => {
    try {
      const fedopsLogger = FedopsLogger.fromControllerConfig(config);
      fedopsLogger.appLoadStarted();
    } catch (e) {
      console.log('[createControllers]: Error creating FedopsLogger');
    }
    const widgetId = config.type;
    if (testWidgets.includes(widgetId)) {
      console.warn(
        `This is a TEST install. Widget ${widgetId} is used mostly locally`,
      );
    }
    const createController = controllerByType[widgetId];
    if (createController) {
      return createController(config, ExperimentsProvider.experimentsBag);
    }
  });
};

function getDefaultAdapter() {
  let adapter;
  const isBrowser = typeof XMLHttpRequest !== 'undefined';
  const isNode =
    typeof process !== 'undefined' &&
    Object.prototype.toString.call(process) === '[object process]';

  if (isNode) {
    console.log(
      '[social-groups-platform] getDefaultAdapter: ',
      'using xhr adapter',
    );
    adapter = require('axios/lib/adapters/xhr');
  } else if (isBrowser) {
    console.log(
      '[social-groups-platform] getDefaultAdapter: ',
      'using http adapter',
    );
    adapter = require('axios/lib/adapters/http');
  } else {
    console.log(
      '[social-groups-platform] getDefaultAdapter: ',
      'failed to determine adapter',
    );
  }

  return adapter;
}
