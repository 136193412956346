import { IPlatformAPI } from '@wix/native-components-infra/dist/src/types/types';
import { EXPERIMENTS_SCOPE } from '../app-configs';

export function getExperimentsFromCache(platformApis: IPlatformAPI) {
  try {
    const cachedExperiments = platformApis.storage.session.getItem(
      EXPERIMENTS_SCOPE,
    );
    if (cachedExperiments) {
      return JSON.parse(cachedExperiments);
    }
  } catch (e) {
    console.log('Error getting experiments from memory cache');
    return;
  }
}
